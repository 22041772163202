import ApiService from "@/services/api_service"
import {IUser, IUserForm} from "@/types"
import qs from "qs"

const UserService = {
  saveUser: (attrs: Partial<IUser>): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        action: "save_user",
        ... attrs
      }
      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }).then(data => {
        resolve(data)
        console.log('UserService saveUser success ', data)
      }).catch(error => {
        reject(error)
        console.log('UserService saveUser error ', error)
      })
    })

  },

  deleteUser: (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        action: "remove_user",
        id
      }
      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }).then(data => {
        resolve(data)
        console.log('UserService deleteUser success ', data)
      }).catch(error => {
        reject(error)
        console.log('UserService deleteUser error ', error)
      })
    })

  },

  convertCustomerToRegularUser: (email: string, id: string, userParams: IUserForm | null): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        action: "customer2user",
        email,
        id,
        user: userParams
      }
      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }).then(data => {
        resolve(data)
        console.log('UserService convertCustomerToRegularUser success ', data)
      }).catch(error => {
        reject(error)
        console.log('UserService convertCustomerToRegularUser error ', error)
      })
    })

  },

  resendInvitation: (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const params = {
        action: "user_resend",
        id
      }
      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }).then(data => {
        resolve(data)
        console.log('UserService resendInvitation success ', data)
      }).catch(error => {
        reject(error)
        console.log('UserService resendInvitation error ', error)
      })
    })

  },

}

export default UserService
