import { defineComponent, onMounted, PropType, ref, toRaw, watch, nextTick, computed, onUnmounted, inject,
  onBeforeUnmount } from "vue";
import FileUpload from 'vue-upload-component'
import { VueUploadItem } from 'vue-upload-component'
import {IUserForm} from "@/types"
import UserAvatar from "@/components/UserAvatar"
import ApiService from "@/services/api_service"
import {useI18n} from "vue-i18n"
import {useStore} from "vuex"
import {autorefreshBlockAdd, autorefreshBlockRemove} from "@/composables/useFetchState"
import {useAppUtils} from "@/composables/useAppUtils"
import SvgIcon from "@/components/SvgIcon/SvgIcon.vue"
import {useLocale} from "@/composables/useLocale"
import {useFilesDrag} from "@/composables/useFilesDrag"

// import { Tippy } from 'vue-tippy'
// import { VTooltip, Tooltip } from 'floating-vue'

export default defineComponent({
  name: "PeopleUserEdit",
  props: {
    modelValue: {
      type: Object as PropType<IUserForm>,
      required: true
    },
/*
    fieldErrors: {
      type: Object as PropType<IFieldErrors>,
      required: false
    },
*/
  },
  emits: ['save', 'cancel', 'delete', 'update:modelValue', 'removeFieldError'],
  components: {
    UserAvatar,
    FileUpload,
    SvgIcon
    // Tippy,
    // VTooltip: Tooltip,
  },

  setup(props, { emit }) {

    //console.log('-------------------------', VTooltip, Tooltip, Tippy)
    console.log('PeopleUserEdit', props, emit)

    console.log('PeopleUserEdit user', FileUpload, ApiService.getDefaultParams())

    const store = useStore()
    const { t } = useI18n()
    const { f } = useLocale()
    //const t = str => str

    const files = ref([])
    const user = ref<IUserForm>({
      ...toRaw(props.modelValue)
    })
    let initialUser: IUserForm = { ...user.value }
    const canChangeAdm = computed(() => store.getters['people/canChangeAdm'])
    const canChangeAccessRights = computed(() => store.getters['people/canChangeAccessRights'])
    const isAccountAdministrator = computed(() => user.value.account_administrator === '1')
    const canRemoveUser = computed(() => store.getters['people/canRemoveUser'])
    const usersLimitReached = computed(() => store.getters.usersLimitReached)
    const refDropElement = ref(null)

    const uploaderInputId = 'avatar_uploader'

    const {
      getAuthUser,
      showElementLoader,
      hideElementLoader,
      showError,
    } = useAppUtils()
    const authUser = getAuthUser()

    const hasChanges = computed(() => {
      const currentUserData = {... user.value}
      const initialUserData = {...initialUser}

      delete currentUserData.change_avatar
      delete initialUserData.change_avatar

      if (currentUserData.password === '') {
        delete currentUserData.password
      }

      if (currentUserData.view_profiles == '0') {
        delete currentUserData.manage_profiles
        delete initialUserData.manage_profiles
      }

      console.log('PeopleUserEdit hasChanges', JSON.stringify(currentUserData))
      console.log('PeopleUserEdit hasChanges', JSON.stringify(initialUserData))
      console.log('PeopleUserEdit hasChanges', JSON.stringify(currentUserData) !== JSON.stringify(initialUserData))
      return JSON.stringify(currentUserData) !== JSON.stringify(initialUserData)
    })

    watch(hasChanges, () => {
      console.log('PeopleUserEdit watch hasChanges.value', hasChanges.value)
      //emit('hasChanges', hasChanges.value, (props.isNew ? 'top' : 'bottom'))
      store.commit('people/setUserFormChanged', hasChanges.value)
    })


    watch(() => props.modelValue, async (curValue/*, prevValue*/) => {
      console.log('PeopleUserEdit watch props.modelValue', curValue)
      user.value = {
        ...toRaw(props.modelValue)
      }
      user.value.userEmailNotify = isNew.value ? '1' : '0'
      initialUser = { ...user.value }
      await nextTick()
      refName.value?.focus()
    });

    const isNew = computed(() => !user.value.id)
    watch(isNew, (value) => {
      user.value.userEmailNotify = value ? '1' : '0'
      initialUser = { ...user.value }
    }, {immediate: true})

    watch(isAccountAdministrator, value => {
      Object.assign(user.value, {
        manage_projects: value ? '1' : '0',
        view_profiles: value ? '1' : '0',
        manage_profiles: value ? '1' : '0',
      })
    })

    onMounted(async () => {
      autorefreshBlockAdd('UserEdit')
      store.commit('people/setUserFormChanged', false)
      await nextTick()
      if (refName.value) {
        refName.value.focus()
      }
    })
    onUnmounted(() => {
      autorefreshBlockRemove('UserEdit')
      store.commit('people/setUserFormChanged', false)
    })

    const preloadImg = (imgURL) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imgURL
        img.onload = () => {resolve(imgURL)}
        img.onerror = () => {reject(imgURL)}
      })
    }

    const uploadElementRef = ref<InstanceType<typeof FileUpload> | null>(null)

    const inputFile = async (newFile: VueUploadItem | undefined, oldFile: VueUploadItem | undefined) => {
      console.log('inputFile', newFile, oldFile)

      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)

        if (uploadElementRef.value) {
          showElementLoader('.user-avatar-area .file-uploads', {bgColor: 'rgba(255,255,255, 0)', scale: 0.3})
          uploadElementRef.value.active = true
        }
      }
      if (newFile && oldFile) {
        // update
        console.log('update', newFile)

        if (!newFile.active && oldFile.active) {
          // Get response data
          console.log('Avatar upload response', newFile.response)

          if (newFile?.response?.code === 0) {
            Promise.all([preloadImg(newFile.response.paths['84']), preloadImg(newFile.response.paths['168'])])
              .then((urls) => {
                user.value.ico_84x84 = urls[0] as string
                user.value.icoX2_84x84 = urls[1] as string
                user.value.change_avatar = 1
              })
              .finally(async() => {
                await nextTick()
                await hideElementLoader('.user-avatar-area .file-uploads')
              })
              .catch((reason) => {
                console.log('User avatar preload error', reason)
              })

          } else if (newFile?.response?.message) {
            showError(newFile?.response?.message)
            await nextTick()
            await hideElementLoader('.user-avatar-area .file-uploads')
          }

          // if (newFile.xhr) {
          //   //  Get the response status code
          //   console.log('status', newFile.xhr.status)
          // }
        }

      }
      if (!newFile && oldFile) {
        // remove
        console.log('remove', oldFile)
      }

    }

    const inputFilter = (newFile: VueUploadItem | undefined, oldFile: VueUploadItem | undefined, prevent: (prevent?: boolean) => boolean) => {
      console.log('inputFilter', newFile, oldFile, prevent)
      if (newFile && !oldFile) {
        // Before adding a file
        const fileType = newFile.type || ''
        if (!['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'].includes(fileType)) {
          showError(t('wrong file extension'), {id: 'wrong file extension'})
          return prevent()
        }

        if (newFile.size && newFile.size > 10 * 1024 * 1024) {
          alertFileSizeLimit(newFile)
          return prevent()
        }

        /*
                // Filter system files or hide files
                if (newFile.name && /(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                  return prevent()
                }

                // Filter php html js file
                if (newFile.name && /\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                  return prevent()
                }
        */
      }

    }

    const alertFileSizeLimit = async (fileItem) => {
      //alert(`File ${fileItem.name} is too big (limit: ${props.sizeLimit})`)
      await store.dispatch('addNotification', {
        //text: `File ${fileItem.name} is too big (limit: ${Math.round(props.sizeLimit / 1024 / 1024)}Mb)`,
        text: f('File "%s" is too large', `<b>${fileItem.name}</b>`),
        type: 'warning',
      })
    }


    const deleteUser = () => {
      console.log('deleteUser', props.modelValue)
      emit('delete', user.value)
    }

    const saveUser = () => {
      console.log('saveUser', JSON.stringify(user.value))
      emit('update:modelValue', user.value);
      console.log('saveUser 2', JSON.stringify(props.modelValue))
      emit('save', user.value)
    }

    const cancel = () => {
      console.log('cancel', user.value, emit)
      emit('cancel', user.value)
    }

    const clearAvatar = () => {
      user.value.change_avatar = 2
      user.value.icoX2_84x84 = ''
      user.value.ico_84x84 = ''

      user.value.icoX2_32x32 = ''
      user.value.ico_32x32 = ''
    }

    const refName = ref()
    const refEmail = ref()
    const refPassword = ref()

    const isDebugMode = !!inject('isDebugMode')

    const dragEnter = ref(false)

    const {
      filesDraggingActive,
      addDropObserverItem,
      removeDropObserverItem,
    } = useFilesDrag()

    let dropObserverUid
    onMounted(() => {
      dropObserverUid = addDropObserverItem(refDropElement, {
        onDragEnter: () => { dragEnter.value = true },
        onDragLeave: () => { dragEnter.value = false },
      })
    })
    onBeforeUnmount(() => {
      removeDropObserverItem(dropObserverUid)
    })

    return {
      t,
      isDebugMode,
      authUser,
      hasChanges,
      canRemoveUser,
      canChangeAdm,
      canChangeAccessRights,
      isAccountAdministrator,
      isNew,
      usersLimitReached,
      files,
      uploadElementRef,
      uploaderInputId,
      user,
      refName,
      refEmail,
      refPassword,
      refDropElement,
      dragEnter,
      filesDraggingActive,
      inputFile,
      inputFilter,
      deleteUser,
      saveUser,
      cancel,
      clearAvatar,
      //removeFieldError,
      uploaderData: {
        ... ApiService.getDefaultParams(),
        user_id: props.modelValue.id
      },
      uploaderAction: ApiService.getBaseURL() + 'upload_avatar.php'
    }
  },

})
