
  import { defineComponent } from 'vue'
  import SvgIcon from "@/components/SvgIcon"

  export default defineComponent({
    name: 'PeopleTopTabs',
    components: {
      SvgIcon
    }
  })
