import {defineComponent, computed, watch, reactive, toRefs, onMounted, ref, onUpdated, onBeforeUnmount, nextTick, inject } from "vue"
import {useStore} from "vuex"
import {useI18n} from "vue-i18n"
import PeopleUserView from "../PeopleUserView"
import PeopleUserEdit from "../PeopleUserEdit"
import AppLoader from "@/components/AppLoader.vue"
import {useDataTable} from "@/composables/useDataTable"
import DataTable from "@/components/DataTable"
import DataPaging from "@/components/DataPaging"
import SvgIcon from "@/components/SvgIcon"
import UserAvatar from "@/components/UserAvatar"
import {IApiResponse, IFieldErrors, IUser, IUserRequest} from "@/types"
import UserService from "@/services/user_service"
import {autorefreshBlockAdd, autorefreshBlockRemove, useFetchState} from "@/composables/useFetchState"
//import {useResizePanels} from "@/composables/useResizePanels"
import Draggable from "vuedraggable"
import {useScreenDocumentTitle} from "@/composables/useScreenDocumentTitle"
import {useFormErrors} from "@/composables/useFormErrors"
import {useAppUtils} from "@/composables/useAppUtils"
import ContentPanels from "@/components/ContentPanels.vue"
import {useCurrentScreen} from "@/composables/useCurrentScreen"
import {onBeforeRouteLeave} from "vue-router"
import _debounce from 'lodash/debounce'
import MenuScreenPeople from "@/components/Menus/MenuScreenPeople.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"
import {useLocale} from "@/composables/useLocale"
import {useScrollKeep} from "@/composables/useScrollKeep"


export default defineComponent({
  name: "PeopleScreen",
  components: {
    PeopleUserView,
    PeopleUserEdit,
    AppLoader,
    DataTable,
    DataPaging,
    SvgIcon,
    UserAvatar,
    Draggable,
    ContentPanels,
    MenuScreenPeople,
  },
  props: {
    selectedItemId: {
      type: String,
      required: false
    },
    order: {
      type: String,
      required: false
    },
    reverse: {
      type: Number,
      required: false
    },
    pageCurrent: {
      type: Number,
      required: false
    },
    search: {
      type: String,
      required: false
    },
    serverParam: {
      type: String,
      required: false
    },
  },

  setup(props/*, context*/) {

    const store = useStore()
    const { t } = useI18n()
    const { f } = useLocale()
    //const { confirm } = useAlertConfirmPopup()

    console.log('PeopleScreen', props, store.state, store)

    const storeNamespace = 'people'

    const moduleName = 'people'

    //const resizablePanel = 'right'

    const datatableItems = computed(() => store.getters[storeNamespace + '/users'])
    const authUser = computed(() => store.getters['auth/authUser'])
    const canAddEditUser = computed(() => authUser.value?.manage_profiles === '1')

    // const loadingsState = reactive({
    //   loadingFull: false, //!datatableItems.value.length,
    //   loadingLeft: false,
    //   loadingRight: false,
    // })

    const {
      vConfirm,
      vEscape,
      isSuccessServerResponse,
      showResponseSuccessMessage,
      isMayContinue,
      hidePanelsLoaders,
      hideStartLoader,
      showRightPanelLoader,
      showPanelsLoaders,
    } = useAppUtils()

    if (datatableItems.value.length) {
      hidePanelsLoaders()
    }

    useCurrentScreen(moduleName)
    useScreenDocumentTitle(moduleName)

    onBeforeRouteLeave(async () => {
      if (!await isMayContinue()) { return false }
    })

    const {
      fetchStateInit,
      changeStateParams,
      fetchState,
      getServerRequestParams,
      processServerResponse,
      initAutorefresh,
      cancelAutorefresh,
    } = useFetchState({
      storeNamespace: moduleName,
    })

    const datatable = useDataTable( {
      storeNamespace: moduleName,
    })

    const {
      scrollToSelected,
    } = datatable

    const afterFetchStateInit = async () => {
      //loadingsState.loadingFull = false
      await nextTick()
      scrollToSelected()
      //requestAnimationFrame(scrollToSelected)
    }

    const emitter: any = inject('emitter');

    const leftPanelScrollContainer = ref()

    const {
      keepScroll,
      restoreScroll,
    } = useScrollKeep()

    onMounted(async () => {
      restoreScroll(leftPanelScrollContainer, moduleName)

      emitter.off('gotoMyProfile', scrollToSelected)
      emitter.on('gotoMyProfile', scrollToSelected)

      emitter.off('confirmAndConvertCustomer', convertCustomerToRegularUser)
      emitter.on('confirmAndConvertCustomer', convertCustomerToRegularUser)

      try {
        await fetchStateInit(props)
        await afterFetchStateInit()
        initAutorefresh()
        watch(() => props.selectedItemId, checkAddNewUser, {immediate: true})
        await nextTick()
        await hidePanelsLoaders()
        hideStartLoader()
      } catch (e) {
        console.log('PeopleScreen onMounted error', e, props)
      }
    })


    const selectedUserStoreComputed = computed(() => store.getters[storeNamespace + '/selectedUser'])
    const selectedUser = ref(null)

    const readSelectedUserFromStore = () => {
      selectedUser.value = selectedUserStoreComputed.value ? {...selectedUserStoreComputed.value} : null
    }

    watch(selectedUserStoreComputed, () => {
      console.log('People screen watch selectedUserStoreComputed', selectedUserStoreComputed.value)
      readSelectedUserFromStore()
    }, {
      immediate: true
    })

    const viewMode = computed(() => store.getters[storeNamespace + '/viewMode'])
    //const textFilter = computed(() => store.getters[storeNamespace + '/search'])

    store.commit(storeNamespace + '/set_search', (props.search || '').trim())
    const textFilter = ref(store.getters[storeNamespace + '/search'])

    const searchSubmit = async () => {
      autorefreshBlockRemove('PeopleScreen_waitSearchSubmit')
      if (textFilter.value === '' || textFilter.value.trim()) {
        const result = await changeStateParams({search: textFilter.value})
        console.log('PeopleScreen searchSubmit result', result)
        if (result !== 'cancel') {
          await hidePanelsLoaders()
        }
      }
    }

    const debouncedSearchSubmit = _debounce(searchSubmit, 500)

    const noteSearchInputHandler = () => {
      autorefreshBlockAdd('PeopleScreen_waitSearchSubmit')
      if (textFilter.value === '' || textFilter.value.trim()) {
        showPanelsLoaders()
      }
      debouncedSearchSubmit()
    }

    watch(textFilter, value => {
      console.log('PeopleScreen debouncedSearchSubmit watch textFilter', Date.now(), textFilter.value)
      if (ignoreSearchModelChange) {
        return
      }
      store.commit(storeNamespace + '/set_search', (value || '').trim())
      noteSearchInputHandler()
    })

    watch(() => store.getters[storeNamespace + '/search'], value => textFilter.value = value)

    let ignoreSearchModelChange = false
    const resetSearch = async () => {
      ignoreSearchModelChange = true
      textFilter.value = ''
      await nextTick()
      ignoreSearchModelChange = false
      store.commit(storeNamespace + '/set_search', '')
    }

    const searchCancel = async () => {
      const isChangeStateParamsNeeded = !!textFilter.value.trim()
      await resetSearch()
      if (isChangeStateParamsNeeded) {
        showPanelsLoaders()
        await changeStateParams({search: ''})
        await hidePanelsLoaders()
      }
    }


    const checkAddNewUser = () => {
      const isNewUser = props.selectedItemId === 'new'
      console.log('PeopleScreen checkAddNewUser', isNewUser)
      store.commit(storeNamespace + '/set_viewMode', props.selectedItemId === 'new' ? 'edit' : 'view')
      if (isNewUser) {
        cancelAutorefresh()
      } else {
        initAutorefresh()
      }
    }

    const clickDatatableRowHandler = async (id: number) => {
      console.log('PeopleScreen clickDatatableRowHandler id', id)
      //if (id !== selectedUser.value?.id) {
      if (id.toString() !== props.selectedItemId) {
        if (!await isMayContinue()) { return false }
        fieldErrors.value = {}
        destroyFormErrors()
        let requestedUser: IUser | undefined = store.getters[storeNamespace + '/getUserById'](id)
        if (requestedUser) {
          requestedUser = {... requestedUser}
          if (requestedUser.ico_32x32) {
            requestedUser.ico_84x84 = requestedUser.ico_32x32.replace('_32.', '_84.')
            requestedUser.icoX2_84x84 = requestedUser.ico_32x32.replace('_32.', '_168.')
          }
        }

        console.log('PeopleScreen clickDatatableRowHandler requestedUser', requestedUser)
        store.commit(storeNamespace + '/set_viewMode', 'view')
        await changeStateParams({selectedItemId: id, selectedUser: requestedUser})
      }
    }

    let userSelectedBeforeAddNew
    const addUserHandler = async () => {
      if (!canAddEditUser.value) {
        return
      }
      if (!await isMayContinue()) { return false }
      showRightPanelLoader('rgba(255,255,255, 1)')
      userSelectedBeforeAddNew = props.selectedItemId
      await changeStateParams({selectedItemId: 'new'})
      store.commit(storeNamespace + '/set_viewMode', 'edit')
      await hidePanelsLoaders()
    }

    const startUserEdit = async (attrs) => {
      showRightPanelLoader()
      store.commit(storeNamespace + '/set_viewMode', 'edit')
      await fetchState()
      await nextTick()
      scrollToSelected()
      await hidePanelsLoaders()
      // const selectedTableRow = document.querySelector('tr.selected[data-id]')
      // const scrollableContainer = document.querySelector('.screen-panel-left-datatable')
      // if (selectedTableRow) {
      //   const selectedTableRowParams = selectedTableRow.getBoundingClientRect()
      //   console.log('selectedTableRowParams', selectedTableRowParams)
      //   if (scrollableContainer && selectedTableRowParams.top < 88) {
      //     scrollableContainer.scrollBy({
      //       top: selectedTableRowParams.top - 88 - 27,
      //       left: 0,
      //       behavior: 'smooth'
      //     })
      //   } else {
      //     scrollToSelected()
      //   }
      // }

    }

    const cancelUserEdit = async (attrs) => {
      console.log('cancelUserEdit', attrs, userSelectedBeforeAddNew)
      fieldErrors.value = {}
      destroyFormErrors()
      store.commit(storeNamespace + '/set_viewMode', 'view')
      if (!attrs.id) {
        store.commit(storeNamespace + '/set_selectedUser', null)
        await changeStateParams({selectedItemId: userSelectedBeforeAddNew || ''})
      } else {
        readSelectedUserFromStore()
      }
    }

    const scrollToFirstError = () => {
      const firstErrorField = document.querySelector('.people-form [data-error-fieldname]')
      if (firstErrorField) {
        firstErrorField.scrollIntoView({
          block: "center",
          inline: "nearest",
          behavior: "smooth",
        })
      }
    }

    const saveUser = async (attrs: Partial<IUserRequest>) => {
      fieldErrors.value = {}
      destroyFormErrors()
      try {
        //loadingsState.loadingRight = true
        showRightPanelLoader()
        const saveRequestParams = {
          ...attrs,
          ...getServerRequestParams()
        }
        const data: IApiResponse = await UserService.saveUser(saveRequestParams)

        console.log('PeopleScreen saveUser data', data)

        await showResponseSuccessMessage(data)
        if (isSuccessServerResponse(data)) {
          store.commit(storeNamespace + '/set_viewMode', 'view')
          await processServerResponse(data)
          await nextTick()
          if (!attrs.id) {
            scrollToSelected()
          }
          if (data?.xdata?.user?.id == authUser.value.id) {
            store.commit('auth/changeAuthUser', {authUser: data.xdata.user})
          }
        }
        //loadingsState.loadingRight = false
      } catch (e) {
        console.log('PeopleScreen saveUser error', e)
        //loadingsState.loadingRight = false
        if (e?.xdata?.errors) {

          // destroyFormErrors()

          fieldErrors.value = { ... e.xdata.errors }

          if (!isFormErrorFieldsRegistered) {
            registerFormErrorFields()
          }

          checkFormErrors()
          scrollToFirstError()

        }
        //return e
      }

      await hidePanelsLoaders()

    }

    const deleteUser = async ({id, name}) => {
      if (await vConfirm(f('Are you sure you want to delete this person?', `<b>${vEscape(name)}</b>`))) {
        showPanelsLoaders()
        fieldErrors.value = {}
        destroyFormErrors()
        try {
          const data = await UserService.deleteUser(id)
          console.log('PeopleScreen deleteUser', data)
          await showResponseSuccessMessage(data)
          if (isSuccessServerResponse(data)) {
            store.commit(storeNamespace + '/set_viewMode', 'view')
            await fetchState()
          }
        } catch (e) {
          console.log('PeopleScreen deleteUser error', e)
          return e
        }
        await hidePanelsLoaders()
      }
    }

    const convertCustomerToRegularUser = async ({email, id}) => {
      console.log('convertCustomerToRegularUser', email, id)
      if (email && await vConfirm('Convert customer with email "' + email + '" to regular user?')) {
        showPanelsLoaders()
        fieldErrors.value = {}
        destroyFormErrors()
        try {
          const data = await UserService.convertCustomerToRegularUser(email, id, selectedUser.value)
          console.log('PeopleScreen convertCustomerToRegularUser', data)
          await showResponseSuccessMessage(data)
          if (isSuccessServerResponse(data)) {
            await changeStateParams({selectedItemId: data?.xdata?.user_id})
            await nextTick()
            scrollToSelected()
          }
        } catch (e) {
          console.log('PeopleScreen convertCustomerToRegularUser error', e)
          return e
        }
        await hidePanelsLoaders()
      }
    }

    // let timer
    // const updateTextFilter = (event) => {
    //   showPanelsLoaders()
    //   clearTimeout(timer)
    //   //store.commit(storeNamespace + '/set_search', event.target.value) // чтобы не мигало значение в input-е
    //   timer = setTimeout(async () => {
    //     await changeStateParams({search: event.target.value})
    //     await hidePanelsLoaders()
    //   }, 500)
    // }

    // const {
    //   resizePanelsStartHandler,
    //   resizePanelsHandler,
    // } = useResizePanels()

    const refScrollableContainerRightPanel = ref()
    const fieldErrors = ref<IFieldErrors>({})
    const refUserEdit = ref()
    const {
      registerField,
      checkFormErrors,
      destroyFormErrors,
    } = useFormErrors('userForm', {
      refResizeObserverTarget: refScrollableContainerRightPanel,
      onRemoveError(fieldName: string) {
        delete fieldErrors.value[fieldName]
      }
    })


    let isFormErrorFieldsRegistered = false
    const registerFormErrorFields = () => {
      registerField({
        fieldName: 'name',
        elementRefGetter: () => refUserEdit.value?.$refs?.refName,
        errorMessageGetter: () => fieldErrors.value?.name,
        tippyProps: {
          //sticky: true,
          appendTo: 'parent'
        }
      })
      registerField({
        fieldName: 'email',
        elementRefGetter: () => refUserEdit.value?.$refs?.refEmail,
        errorMessageGetter: () => fieldErrors.value?.email,
        tippyProps: {
          //sticky: true,
          placement: 'bottom-start',
          zIndex: 1,
          appendTo: 'parent'
        }
      })
      registerField({
        fieldName: 'password',
        elementRefGetter: () => refUserEdit.value?.$refs?.refPassword,
        errorMessageGetter: () => fieldErrors.value?.password,
        tippyProps: {
          //sticky: true,
          placement: 'bottom-start',
          zIndex: 1,
          appendTo: 'parent'
        }
      })

      isFormErrorFieldsRegistered = true
    }

    onUpdated(() => {
      if (fieldErrors.value && Object.keys(fieldErrors.value).length) {
        checkFormErrors()
      }
    })

    onBeforeUnmount(() => {
      keepScroll(leftPanelScrollContainer, moduleName)
      emitter.off('gotoMyProfile', scrollToSelected)
      destroyFormErrors()
      cancelAutorefresh()
    })

    const refMenuButton = ref()
    const refScreenMenu = ref<InstanceType<typeof MenuScreenPeople> | null>(null)
    const {isExist: isExistScreenMenu, triggerHandler: triggerHandlerScreenMenu} = useFloatingExists(refScreenMenu)

    const screenMenuButtonHandler = async () => {
      console.log('PeopleScreen screenMenuButtonHandler')
      await triggerHandlerScreenMenu()
    }

    const screenMenuActionHandler = async (action, data: any = {}) => {
      console.log('PeopleScreen screenMenuActionHandler', action, data)
      if (refScreenMenu.value) {
        refScreenMenu.value.isOpened = false
      }

      switch (action) {
        case 'resetView':
          await datatable.resetView()
          break
      }
    }

    return {
      t,
      //resizablePanel,
      ...datatable,
      //...toRefs(loadingsState),
      datatableItems,
      canAddEditUser,
      selectedUser,
      viewMode,
      textFilter,
      changeStateParams,
      fieldErrors,
      refUserEdit,
      refScrollableContainerRightPanel,
      refMenuButton,
      refScreenMenu,
      isExistScreenMenu,
      // resizePanelsStartHandler,
      // resizePanelsHandler,
      clickDatatableRowHandler,
      startUserEdit,
      cancelUserEdit,
      saveUser,
      deleteUser,
      //updateTextFilter,
      addUserHandler,
      searchCancel,
      //removeFieldError,
      screenMenuButtonHandler,
      screenMenuActionHandler,
      leftPanelScrollContainer,
    }
  },

});

