import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header-tabs self-end" }
const _hoisted_2 = {
  key: 0,
  class: "left-corner"
}
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = {
  key: 1,
  class: "right-corner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: {name: 'People'},
      key: "PeopleTopTabs",
      custom: ""
    }, {
      default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
        _createElementVNode("li", {
          class: _normalizeClass({active: isActive || isExactActive})
        }, [
          isActive
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_svg_icon, { name: "tab-corner" })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("a", {
            href: href,
            onClick: navigate
          }, "People", 8, _hoisted_3),
          isActive
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createVNode(_component_svg_icon, { name: "tab-corner" })
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    })
  ]))
}