import {computed, defineComponent, PropType, ref } from "vue";
import {IApiResponse, IUser} from "@/types"
import UserAvatar from "@/components/UserAvatar"
import {useI18n} from "vue-i18n"
import expandToHashMap from "@popperjs/core/lib/utils/expandToHashMap"
import {autorefreshBlockAdd, autorefreshBlockExists, autorefreshBlockRemove} from "@/composables/useFetchState"
import UserService from "@/services/user_service"
import {useAppUtils} from "@/composables/useAppUtils"
import {useStore} from "vuex"
import SvgIcon from "@/components/SvgIcon";

export default defineComponent({
  name: "PeopleUserView",
  props: {
    modelValue: {
      type: Object as PropType<IUser>,
      required: true
    }
  },
  emits: ['edit'],
  components: {
    UserAvatar,
    SvgIcon
  },
  setup(props, {emit}) {
    console.log('PeopleUserView', props)

    const { t } = useI18n()
    const store = useStore()

    const userCanEdit = computed(() => store.getters['people/userCanEdit'])
    const userCompanyAndTitle = computed(() => {
      const stringParts : string[] = [];
      return ['title', 'company'].reduce((previousValue, currentValue) => {
        if (props.modelValue[currentValue]) {
          previousValue.push(props.modelValue[currentValue])
        }
        return previousValue
      }, stringParts).join(', ')

    })

    const clickEditUserHandler = () => {
      emit('edit', props.modelValue)
    }

    const {
      isSuccessServerResponse,
    } = useAppUtils()

    const isSendingInvitation = ref(false)
    const isSentInvitation = ref(false)

    const clickResendInvitationHandler = async () => {
      const id = props.modelValue.id
      const blockType = 'PeopleUserView_resendInvitation'
      const blockAttrs = {id}
      console.log('PeopleUserView clickResendInvitationHandler', id)
      if (autorefreshBlockExists(blockType, blockAttrs)) {
        return false
      }
      autorefreshBlockAdd(blockType, blockAttrs)
      isSendingInvitation.value = true
      try {
        const data: IApiResponse = await UserService.resendInvitation(id)
        if (isSuccessServerResponse(data)) {
          isSentInvitation.value = true
          setTimeout(() => {
            isSentInvitation.value = false
            //store.commit('people/updateUser', {id, attrs: {last_activity: 'invitation sent'}})
            store.commit('people/set_selectedUser', {
              ...props.modelValue,
              last_activity: 'invitation sent'
            })
          }, 300)
        }

      } catch (e) {
        console.log('PeopleUserView resendInvitation error', e)
      }
      isSendingInvitation.value = false
      autorefreshBlockRemove(blockType, blockAttrs)
    }

    return {
      t,
      userCanEdit,
      userCompanyAndTitle,
      isSendingInvitation,
      isSentInvitation,
      clickEditUserHandler,
      clickResendInvitationHandler,
    }
  },


})
